<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />
    <!-- แสดงภาพ -->
    <Photo
      v-if="picturesShow.ShowPhoto"
      :showphoto="picturesShow.ShowPhoto"
      :showphotolink="picturesShow.ShowPhotoLink"
      @emitshowphoto="closePhoto"
    />
    <!-- แสดงรูปภาพที่ถ่าย
    <h3>Captured Images:</h3>
      <div v-for="(image, index) in capturedImages" :key="index" class="captured-image">
        <img :src="image" :alt="'Captured Image ' + (index + 1)" width="200">
        <v-btn @click="downloadImage(image, index)">Download Image {{ index + 1 }}</v-btn>
      </div> -->

      <v-navigation-drawer app   right  permanent v-if="UpDownloadingGoogleDrive"> 
        <v-list-item-content>
          <v-list-item >สำเร็จ {{countSuccessUpload}} /{{ isWaitingUploadGoogleDrive.length}}</v-list-item>
          <v-list-item
            v-for="item in isWaitingUploadGoogleDrive"
            :key="item.fileName"
          >  <img :src="item.thumbnail" width="50" />  
           ชื่อ : {{ item.fileName }}   สถานะ : {{  item.status }} </v-list-item>
        
          </v-list-item-content>
   
      </v-navigation-drawer>
     
    <v-card :class="UpDownloadingGoogleDrive ?  'header':'' ">
  
      <v-card-text>
          <table style="width: 100%;">
            <td style="width: 80px;"  >
              <b> OrderID :</b>
            </td>
            <td style="width: 150px;" class="px-1 ">
              <v-text-field
                autofocus
                v-model="OrderID"
                ref="OrderID"
                densed
                hide-details="auto"
                @keyup.enter="btnSearchOrderID()">
              </v-text-field>
            </td>
            <td  >
              <v-btn color="primary" @click="btnSearchOrderID()">ค้นหา</v-btn>
            </td>

            <td class="jt_end">
              <v-btn  @click="btnBack()">ย้อนกลับ</v-btn>
              <v-list-item-icon >
            <v-icon v-if="isGoogleDrive" @click="UpDownloadingGoogleDrive  = !UpDownloadingGoogleDrive">mdi-dots-vertical</v-icon>

          </v-list-item-icon>
            </td>
            
          </table>
        
    
    </v-card-text>
    </v-card>
    <!-- ส่วนของข้อมูลทั้งหมด -->
    <div v-if="show" class="mt-8   " :class="UpDownloadingGoogleDrive ?  'margin-left256':'' ">
      <!-- ที่อยู่ผู้ส่ง -->
      <v-row>
        <v-col cols="6">
          <v-card  style="height: 200px;"
        v-if="!platformReferenceSourceID.includes(orderInfo.referenceSourceID)"
        elevation="1"
        class="v-card-margin-bottom-30"
      >
        <v-card-title> ที่อยู่ผู้ส่ง </v-card-title>
        <v-card-text  >
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> ชื่อผู้ส่ง : </v-col>
            <v-col cols="8" md="9" lg="10">
              {{ orderInfo.senderAddress.name }}
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> เบอร์ : </v-col>
            <v-col cols="8" md="9" lg="10">
              {{ orderInfo.senderAddress.phone }}
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> ที่อยู่ : </v-col>
            <v-col cols="8" md="9" lg="10">
              {{ orderInfo.senderAddress.address }} &nbsp;
              {{ orderInfo.senderAddress.subDistrict }} &nbsp;
              {{ orderInfo.senderAddress.district }} &nbsp;
              {{ orderInfo.senderAddress.province }} &nbsp;
              {{ orderInfo.senderAddress.postcode }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
        </v-col>
        <v-col cols="6">
          <v-card  style="height: 200px;"
        v-if="
          !platformReferenceSourceID.includes(orderInfo.referenceSourceID) &&
          orderInfo.shippingAddressID
        "
        elevation="1"
        class="v-card-margin-bottom-30"
      >
        <v-card-title> ที่อยู่ผู้รับ </v-card-title>
        <v-card-text >
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> ชื่อผู้รับ : </v-col>
            <v-col cols="8" md="9" lg="10">
              {{ orderInfo.receiverAddress.name }}
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> เบอร์ : </v-col>
            <v-col cols="8" md="9" lg="10">
              {{ orderInfo.receiverAddress.phone }}
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> ที่อยู่ : </v-col>
            <v-col cols="8" md="9" lg="10">
              {{ orderInfo.receiverAddress.address }} &nbsp;
              {{ orderInfo.receiverAddress.subDistrict }} &nbsp;
              {{ orderInfo.receiverAddress.district }} &nbsp;
              {{ orderInfo.receiverAddress.province }} &nbsp;
              {{ orderInfo.receiverAddress.postcode }}
            </v-col>
          </v-row>
          
        </v-card-text>
      </v-card>

        </v-col>
      </v-row> 
    

      <!-- ที่อยู่ผู้รับ -->
  
      <!-- แพลตฟอร์ม -->
      <v-card
        v-if="this.orderItemTemp.length > 0"
        elevation="1"
        class="v-card-margin-bottom-30"
      >
        <v-card-title> แพลตฟอร์ม </v-card-title>
        <v-card-text>
          <v-row class="pl-6 pb-3">
            <v-col cols="4" md="3" lg="2"> เลขอ้างอิง : </v-col>
            <v-col cols="8" md="9" lg="10">
              {{ orderInfo.referenceNumber }}
            </v-col>
          </v-row>
          <v-row class="pl-6 pb-3">
            <v-col cols="4" md="3" lg="2"> ช่องทาง : </v-col>
            <v-col cols="8" md="9" lg="10">
              <a
                v-if="
                  orderInfo.channelLogo != null && orderInfo.channelLogo != ''
                "
                icon
                @click="openPhoto(orderInfo.channelLogo)"
              >
                <v-img
                  style="margin: 5px"
                  v-if="
                    orderInfo.channelLogo != '' && orderInfo.channelLogo != null
                  "
                  :src="orderInfo.channelLogo"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  max-width="30"
                  max-height="30"
                ></v-img>
              </a>
            </v-col>
          </v-row>
          <v-row class="pl-6 pb-3" v-if="orderInfo.tempSenderAddress">
            <v-col cols="4" md="3" lg="2"> ผู้ส่ง : </v-col>
            <v-col cols="8" md="9" lg="10">
              {{ orderInfo.tempSenderAddress }}
            </v-col>
          </v-row>
          <v-row class="pl-6 pb-3">
            <v-col cols="4" md="3" lg="2"> ผู้รับ : </v-col>
            <v-col cols="8" md="9" lg="10">
              {{ orderInfo.tempAddress }}
            </v-col>
          </v-row>

          <!-- PC hidden-xs-only -->
          <v-row class="pl-6 pb-3">
            <v-col cols="4" md="3" lg="2"> ที่ต้องแพ็ค : </v-col>
            <v-col cols="8" md="9" lg="10" class="hidden-xs-only">
              <v-simple-table class="packhai-border-table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center" width="90px">รูป</th>
                      <th class="text-left" width="650px">ชื่อ</th>
                      <th class="text-left" width="100px">SKU</th>
                      <th class="text-right" width="70px">จำนวน</th>
                      <th class="text-right" width="170px">ราคาต่อหน่วย</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in orderItemTemp" :key="item.name">
                      <td class="text-center">
                        <a
                          v-if="item.imgUrl != null && item.imgUrl != ''"
                          icon
                          @click="openPhoto(item.imgUrl)"
                        >
                          <v-img
                            style="margin: 5px"
                            :src="item.imgUrl"
                            aspect-ratio="1"
                            class="grey lighten-2"
                            width="50px"
                            height="50px"
                          ></v-img>
                        </a>
                      </td>
                      <td class="text-left">
                        <div v-if="item.notSku" class="color-red">
                          {{ item.name }}
                          <br />
                          ไม่พบสินค้าในระบบ
                        </div>
                        <div v-else>
                          {{ item.name }}
                        </div>
                      </td>
                      <td class="text-left">
                        <div v-if="item.notSku" class="color-red">
                          {{ item.sku }}
                        </div>
                        <div v-else>
                          {{ item.sku }}
                        </div>
                      </td>
                      <td class="text-right">{{ item.quantity }}</td>
                      <td class="text-right">{{ item.unitPrice }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- รูปแบบพัสดุ -->
      <v-card elevation="1" ref="packingItem" class="v-card-margin-bottom-30">
        <v-card-title> รูปแบบพัสดุ </v-card-title>
        <v-card-text class="pl-10">
          <v-row>
            <v-col cols="12">
              <span v-if="orderDetail.isCOD == false">
                <v-chip color="info" outlined> No-COD </v-chip>
              </span>
              <span v-else>
                <v-chip class="mr-5" color="error"> COD </v-chip>
                <!-- {{orderInfo}} -->
                <!-- {{ orderDetail }} -->
                <span
                  v-if="
                    orderDetail.codAmount != null && orderDetail.codAmount != ''
                  "
                >
                  ยอด {{ formatMoney(orderDetail.codAmount) }} บาท
                </span>
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- หมายเหตุ -->
      
      <v-card elevation="1" ref="PackageSize" class="v-card-margin-bottom-30">
        <v-card-title> หมายเหตุ </v-card-title>
        <v-card-text class="pl-10"> .... </v-card-text>
      </v-card>
      <v-card elevation="1"  style="    margin-bottom: 60px !important;">
      


      <v-card-title> รายการพัสดุต้องแพ็ค </v-card-title>
      <v-card-text >
      <v-row  >
        <v-col cols="12">
          <v-tabs v-model="orderItem.tab"   > 
            <v-tab
              v-for="i in orderItem.packages"
              class='box-shadow'
              :key="i.packageNo"
              @click="orderItem.tab = i.packageNo"
            >
              พัสดุ # {{ i.packageNo }} 
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
        
      
    <v-tabs-items v-model="orderItem.tab" touchless >
      <v-tab-item v-for="packageItem in orderItem.packages" :key="packageItem.packageNo">
        <br>        
          <table>
            <tr>
              <td>
                Barcode/SKU : 
              </td>
              <td class="pl-3">
                <v-text-field
                style="width:250px;"
                v-model="packingCodeAdd"
                id="packingCodeAd"
                outlined
                hide-details
                dense
                 
                @keyup.enter="addItemPacking(packageItem)"
              ></v-text-field>
              </td>
              <td class="pl-3">
                <v-btn color="info"   outlined @click="openAddItemsIncrease(packageItem)">
                  <v-icon size="14" class="pr-1">fa-solid fa-plus</v-icon> เพิ่มรายการ
                </v-btn>
              </td>
              <td>
                <v-btn
                  v-if="ShowSplitMenu(packageItem.items)"
                  color="primary"
                  outlined
                  @click="btnSplitPackage(packageItem.items)"
                  >
                  แยกพัสดุ
                </v-btn>
         
              </td>
            </tr>
          </table>
          
          <v-data-table 
            :headers="orderItem.headers"
            :items="packageItem.items"
          
            fixed-header
            :items-per-page="9999999999"
            hide-default-footer
            class="elevation-1 mt-3 packhai-table packhai-border-table"
          >
          <template v-slot:item="{ item }">
              <tr :class="changeColorHeader(item)">
                 
                <td class="text-center">
                  <a
                    v-if="item.photoLink != null && item.photoLink != ''"
                    icon
                    @click="openPhoto(item.photoLink)"
                  >
                    <v-img
                      style="margin: 5px"
                      v-if="item.photoLink != '' && item.photoLink != null"
                      :src="item.photoLink"
                      aspect-ratio="1"
                      class="grey lighten-2"
                      max-width="80"
                      max-height="80"
                    ></v-img>
                  </a>
                </td>
                <td>{{ item.productName }}</td>
                <td>{{ item.sku }}</td>
                <td>{{ item.barcode }}</td>
                <td class="text-center">
                  <span v-if="item.isLock">{{ item.quantity }}</span>
                </td>
                <td class="text-center">
                  <span v-if="item.packedQuantity">
                    {{ item.packedQuantity }}
                  </span>
                  <span v-else> 0 </span>
                </td>
                <td class="text-center">
                  <a
                    @click="DeleteItemEachPacking(packageItem.items.indexOf(item),packageItem,1)"
                    >ลบจำนวน</a
                  >
                </td>
                <td class="text-center">
                  <a
                    @click="DeleteItemEachPacking(packageItem.items.indexOf(item),packageItem,2)"
                    >ลบทั้งหมด</a
                  >
                </td>
                <td>
                  <span v-if="!item.quantity && !item.isBox">
                    ไม่ใช้สินค้าที่ต้องแพ็ค
                  </span>
                  <span v-else-if="item.isBox"></span>
                  <span
                    v-else-if="item.quantity - item.packedQuantity > 0"
                    style="color: red"
                  >
                    ยังขาดอีก {{ item.quantity - item.packedQuantity }}</span
                  >
                  <span
                    v-else-if="item.quantity - item.packedQuantity < 0"
                    style="color: red"
                  >
                    เกิน {{ item.packedQuantity - item.quantity }}</span
                  >

                  <span
                    v-else-if="(item.quantity = item.packedQuantity)"
                    style="color: green"
                  >
                    ครบ
                  </span>
                  <span v-else> </span>
                </td>
                
              </tr>
            </template>
      
          </v-data-table>
         
        <!-- ขนส่ง -->
          <div
            v-if="!platformReferenceSourceID.includes(orderInfo.referenceSourceID)"
            elevation="1"
            class="mt-10"
          >
            <h3 class="mb-5">ขนส่ง </h3>
          
             
              <v-radio-group v-model="packageItem.uniqueID" row @change="changedExpress($event,packageItem)">
                <v-radio style="margin-top: 20px; margin-left: 20px;"
                  v-for="items in orderInfo.expressChannelToShow"
                
                  :key="items.indexOf"
                  :value="items.uniqueID"
                  :label="items.expressCompanyName"
                >
                  <template v-slot:label  >
                    <v-img
                      :src="items.expressCompanyLogo"
                      width="50"
                      height="auto"
                    ></v-img>
                    <h4>&nbsp;&nbsp;{{items.expressCompanyName}} </h4>
                  </template>
                </v-radio>
              </v-radio-group>
           
          </div>

      <!-- ขนาดบรรจุภัณฑ์ -->
     
          <h3  class="mt-10"> ขนาดบรรจุภัณฑ์ </h3>
       
          <v-row class="mt-5">
            <!-- คำนวณค่าแพ็ค -->
            <v-col>
              <!-- น้ำหนัก(ฺBT) -->
              <!-- <v-row class="mt-2">
                <v-col cols="5" class="text-center">
                  <span>น้ำหนัก(ฺBT) : </span>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    v-model="packageItem.weightBT"
                    outlined
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row> -->
              <!-- น้ำหนัก -->
              <v-row class="mt-2">
                <v-col cols="5" class="text-left">
                  <span>น้ำหนัก : </span>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    v-model="packageItem.weight"
                    outlined
                    type="number"
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- กว้าง cubic3 -->
              <v-row  class=" mt-2">
                <v-col cols="5" class="text-left ">
                  <span>กว้าง : </span>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    v-model="packageItem.cubic3"
                    outlined
                    type="number"
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- ยาว cubic1 -->
              <v-row   class=" mt-2">
                <v-col cols="5" class="text-left">
                  <span>ยาว : </span>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    v-model="packageItem.cubic1"
                    outlined
                    type="number"
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- สูง cubic2 -->
              <v-row  class=" mt-2">
                <v-col cols="5" class="text-left">
                  <span>สูง : </span>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    v-model="packageItem.cubic2"
                    outlined
                    type="number"
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- ค่ากล่อง -->
              <v-row  class=" mt-2">
                <v-col cols="5" class="text-left">
                  <span>ค่ากล่อง : </span>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    v-model="packageItem.boxPrice"
                    outlined
                    type="number"
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- ค่าแพ็ค -->
              <v-row   class=" mt-2">
                <v-col cols="5" class="text-left">
                  <span>ค่าแพ็ค : </span>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    v-model="packageItem.packPrice"
                    outlined
                    type="number"
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- ค่า Bubble -->
              <v-row  class=" mt-2">
                <v-col cols="5" class="text-left">
                  <span>ค่า Bubble : </span>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    v-model="packageItem.bubblePrice"
                    outlined
                    type="number"
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- ค่าส่ง -->
              <v-row  class=" mt-2">
                <v-col cols="5" class="text-left">
                  <span>ค่าส่ง : </span>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    v-model="packageItem.deliveryPrice"
                    outlined
                    type="number"
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- % COD -->
              <v-row  class=" mt-2">
                <v-col cols="5" class="text-left">
                  <span>% COD : </span>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    v-model="packageItem.percentCod"
                    outlined
                    type="number"
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- ค่า COD -->
              <v-row  class=" mt-2">
                <v-col cols="5" class="text-left">
                  <span>ค่า COD : </span>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    v-model="packageItem.codCharged"
                    outlined
                    type="number"
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- พื้นที่ห่างไกล -->
              <v-row  class=" mt-2">
                <v-col cols="5" class="text-left">
                  <span>พื้นที่ห่างไกล : </span>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    v-model="packageItem.remotePrice"
                    outlined
                    type="number"
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- ส่วนลด -->
              <v-row  class=" mt-2">
                <v-col cols="5" class="text-left">
                  <span>ส่วนลด : </span>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    v-model="packageItem.discount"
                    outlined
                    type="number"
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- ค่าใช้จ่ายอื่น -->
              <v-row  class=" mt-2">
                <v-col cols="5" class="text-left">
                  <span>ค่าใช้จ่ายอื่น : </span>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    v-model="packageItem.otherServicePrice"
                    outlined
                    type="number"
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <!-- เลือกขนาดของกล่อง -->
            <v-col cols="8">
              <v-checkbox
                v-model="packageItem.bubble"
                label="มีใช้บริการห่อ Bubble"
                @click="btnBoxSize(packageItem)"
              ></v-checkbox>

              <v-data-table
                v-model="packageItem.selected"
                :headers="orderPackageSize.headers"
                :items="packageItem.postboxes"
                hide-default-footer
                height="50vh"
                fixed-header
                show-select
                single-select
                item-key="name"
                item-value="item"
                class="elevation-1"
                return-object
                @item-selected="selectedBox($event,packageItem)"
               
                
              >
                <template v-slot:header.data-table-select="{ on, props }">
                  <span>เลือก</span>
                </template>
                <template v-slot:item.size="{ item }">
                  <span></span>
                  <span>{{ item.cubic1 }} x </span>
                  <span>{{ item.cubic2 }} x </span>
                  <span>{{ item.cubic3 }}</span>
                </template>
               
              </v-data-table>
            </v-col>
          </v-row>
    
    

      
    </v-tab-item>
    </v-tabs-items>
  </v-card-text>
      </v-card>
    </div>
    <v-footer
     app 
     fixed
    class="bg-indigo-lighten-1 text-center d-flex flex-column"
    v-if="show" 
    >

      <v-card  style="width:100%; ">
        
        
          <v-row class="ma-1 item-center"      >
            <v-col cols="6" class="jt_Start item-center"  >
              <template v-if="isVideoVisible" >
                <video  autoplay playsinline muted ref="video"  width="90"> </video>
              <v-btn icon><v-icon size="18" color="red" @click="captureImage(item)">fa-camera</v-icon></v-btn>
              </template>
            </v-col>
            <v-col cols="6" class="jt_end item-center">
              <v-btn color="primary"  @click="btnSubmitSavePack()" >บันทึก</v-btn >
                <v-btn class="ml-2" color="success" @click="btnSubmitAwb()">บันทึกและปริ้น</v-btn>
            </v-col>
          </v-row>
      
        
      </v-card>
  
  </v-footer>
    
      <!-- แยกพัสดุ -->
    <v-dialog
      v-model="splitPackagePopup.dialog"
      scrollable
      :max-width="splitPackagePopup.MaxWidth"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card elevation="1" class="ma-0">
        <v-card-title class="height-57 pa-0">
          <div class="v-list-item">
           
            <div class="v-list-item__content pa-2">
             ยืนยันพัสดุที่ต้องการแยก
            </div>
            <div>
              <v-btn icon @click="splitPackagePopup.dialog = false"
                ><v-icon color="danger">mdi-close</v-icon></v-btn
              >
            </div>
          </div>
        </v-card-title>
        <v-card >
          <v-data-table
            :headers="splitPackageDataSet.headers"
            :items="splitPackageDataSet.dataSet"
            height="50vh"
            fixed-header
            :items-per-page="9999999999"
            hide-default-footer
            class="elevation-1"
            item-value="productName"
            item-key="productName"
          >
        <template v-slot:item.photoLink="{ item }">
          <a
          v-if="item.photoLink != null && item.photoLink != ''"
          icon
          @click="openPhoto(item.photoLink)"
        >
          <v-img
            style="margin: 0px 0px 0px 20px "
            v-if="item.photoLink != '' && item.photoLink != null"
            :src="item.photoLink"
            aspect-ratio="1"
            class="grey lighten-2"
            max-width="50"
            max-height="50"
          ></v-img>
        </a>
      </template>
        </v-data-table>
        </v-card>
        
        <v-divider />
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              เลือกทั้งหมด {{ splitPackageDataSet.dataSet.length }} รายการ
            </v-col>
            <v-col cols="6" align="right">
              <v-btn color="primary"  @click="btnSubmitSplitPackage()">
                 ดำเนินการ 
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2"; 
import Loading from "@/website/components/Loading";
import Photo from "@/website/components/photo";

import {
  shopService_dotnet,
  orderService_dotnet,
  stockService_dotnet,
  branchService_dotnet,
  generalService_dotnet,
  logisticService_dotnet
} from "@/website/global";
import { ChangeReferenceInfo } from "@/website/global_function";
import { AlertError } from "@/website/global_alert";
import RecordRTC from "recordrtc";
import moment from "moment";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default {
  components: {
    Loading,
    Photo,
    
  },

  data: () => ({
    header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
    page_loading: true,
    loading: false,
    OrderID: null,
    platformReferenceSourceID: [3, 4, 5, 11],
    orderInfo: {},
    orderDetail: { receiver: "", sender: "" },
    orderItem: {
      headers: [
       
        {
          text: "รูป",
          align: "center",
          sortable: false,
          value: "photoLink",
          width: "100px",
        },
        {
          text: "ชื่อ",
          align: "left",
          sortable: false,
          value: "productName",
          width: "300px",
        },
        {
          text: "SKU",
          align: "left",
          sortable: false,
          value: "sku",
          width: "150px",
        },
        {
          text: "Barcode",
          align: "left",
          sortable: false,
          value: "barcode",
          width: "200px",
        },
        {
          text: "จำนวนต้องแพ็ค",
          align: "center",
          sortable: false,
          value: "quantity",
          width: "120px",
        },
        {
          text: "จำนวนแพ็คจริง",
          align: "center",
          sortable: false,
          value: "packedQuantity ",
          width: "120px",
        },
        {
          text: "ลบจำนวน",
          align: "center",
          sortable: false,
          value: "deleteQuantity",
          width: "120px",
        },
        {
          text: "ลบทั้งหมด",
          align: "center",
          sortable: false,
          value: "deleteQuantity",
          width: "120px",
        },
        {
          text: "สถานะ",
          align: "left",
          sortable: false,
          value: "status",
          
        } 
      ],
      packages: [
      ],
      isLock: false,
      tab:0,
      tab_list:[],
      selected:[]
    },
    orderItemTemp: [],
    orderPackageSize: {
      bubble: false,
      weightBT: 0,
      weight: 0,
      long: 0,
      width: 0,
      height: 0,
      boxValue: 0,
      PackValue: 0,
      bubbleFee: 0,
      deliveryFee: 0,
      percentCod: 0,
      codFee: 0,
      remoteArea: 0,
      discount: 0,
      otherExpenses: 0,
      headers: [
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "name",
          width: "50px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "size",
          width: "50px",
        },
        {
          text: "ค่ากล่อง",
          align: "center",
          sortable: false,
          value: "boxSellPrice",
          width: "50px",
        },
        {
          text: "ค่าแพ็ค",
          align: "center",
          sortable: false,
          value: "packPrice",
          width: "50px",
        },
        {
          text: "ค่า Bubble",
          align: "center",
          sortable: false,
          value: "bubblePrice",
          width: "50px",
        },
      ],
      boxSize: [
        {
          name: "D",
          cubic1: 22, //ยาว
          cubic2: 35, //สูง
          cubic3: 14, //กว้าง
          boxSellPrice: 5,
          packAndBoxPrice: 16,
          packPrice: 0, //ค่าแพ็ค
          bubblePrice: 3,
        },
        {
          name: "E",
          cubic1: 24, //ยาว
          cubic2: 40, //สูง
          cubic3: 17, //กว้าง
          boxSellPrice: 17,
          packAndBoxPrice: 17,
          packPrice: 0, //ค่าแพ็ค
          bubblePrice: 5,
        },
      ],
      selected: [],
    },
    currentShopID:null,
    //ส่วนของ Google Drive 
    folderSub:null,
    jsonClent:null,
    //แสดงภาพ
    picturesShow: {
      ShowPhotoLink: "",
      ShowPhoto: false,
    },

    //เสียง
    audioScan : new Audio(require('../deliveryNote/success_scan.mp3')),
    audioBuzzer : new Audio(require('../deliveryNote/buzzer.wav')),

    //รหัสที่เพิ่มสินค้าที่แพ็ค
    packingCodeAdd: null,
    //นำนวนที่ต้องการเพิ่ม
    addItemsIncrease:1,
    show: false,

    //ถ่ายภาพ 
    img: null,
    camera: null,
    deviceId: null,
    devices: [],
    splitPackageDataSet:{
      headers: [

        {
          text: "รูป",
          align: "center",
          sortable: false,
          value: "photoLink",
          width: "50px",
        },
        {
          text: "ชื่อ",
          align: "center",
          sortable: false,
          value: "productName",
          width: "50px",
        },
        {
          text: "SKU",
          align: "center",
          sortable: false,
          value: "sku",
          width: "50px",
        },
        {
          text: "Barcode",
          align: "center",
          sortable: false,
          value: "barcode",
          width: "50px",
        },
        // {
        //   text: "จำนวนที่มี",
        //   align: "center",
        //   sortable: false,
        //   value: "quantity",
        //   width: "50px",
        // },
        {
          text: "จำนวนที่ต้องการแยก",
          align: "center",
          sortable: false,
          value: "packedQuantity",
          width: "50px",
        },
        
        
      
      ],
      dataSet: [
      ],
      selected: [],
    },
    splitPackagePopup: {
      dialog: false,
      MaxWidth: "60%",
      MaxHeight: 70,
      
    },
    selectedStock:[],

    //วีดีโอ
    recorder: null,
    disabled: false,
    downloadReady: false,
    recordedBlob: null,
    isVideoVisible: false,
    capturedImages: [],
    thumbnailImg:null,
    
    isGoogleDrive:false,
    isGetFolderGoogleDrive:'',
    isWaitingUploadGoogleDrive:[],
    UpDownloadingGoogleDrive:false,
    countSuccessUpload:0,
  }),
  async created() {
    console.log("11.02 Check refreshToken");

    this.isGetFolderGoogleDrive = await this.getGoogleDriveFolder();
    if(this.isGetFolderGoogleDrive == null ||this.isGetFolderGoogleDrive == [] ){
      this.isGoogleDrive = false;
    }else{
      this.isGoogleDrive = true;
    }
    
    this.page_loading = false;
   
    
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeUnload);
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  computed: 
  {
  },
  watch: {
   async show() {
      if (this.show)
        setTimeout(() => {
          const inputElement = document.getElementById("packingCodeAd");
          inputElement.focus();
          this.$vuetify.goTo(this.$refs.packingItem);
        }, 100);
    },

    async orderItem(){
      // console.log(this.orderItem,"orderItem.packages")
    } 
  },
  methods: {
    //ส่วนของ API
    async getCheckOrderStatusBeforePack(OrderMasterID, TrackingNo) {
      return await axios
        .get(
          orderService_dotnet +
            "Order/check-order-status-before-pack?" +
            "StaffBranchID=" +
            localStorage.getItem("Branch_StaffID") +
            "&OrderMasterID=" +
            OrderMasterID +
            "&TrackingNo=" +
            TrackingNo,
          { headers: this.header_token }
        )
        .then(async (res) => {
          
          if (res.data.isStop) {
            this.loading = false;
            return await this.alertStopPack(res.data);
          }
          if (res.data.isConfirm) {
            this.loading = false;
            return await this.alertConfirm(res.data);
          }
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.audioBuzzer.play()
          this.AlertError();
          return false;
        });
    },
    async getOrderMasterForPack(orderID) {
      return await axios
        .get(
          orderService_dotnet +
            "Order/get-order-master-for-pack?OrderMasterID=" +
            orderID,
          { headers: this.header_token }
        )
        .then(async (res) => {
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async getOrderItemPackageForPack(orderID) {
      return await axios
        .post(
          orderService_dotnet + "Package/get-order-package-for-pack",
          { orderID: orderID },
          { headers: this.header_token }
        )
        .then(async (res) => {
          
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async getOrderItemTemp(orderID) {
      return await axios
        .post(
          orderService_dotnet + "Order/get-order-item-temp",
          { orderID: orderID },
          { headers: this.header_token }
        )
        .then(async (res) => {
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async getShippingAddressList(shippingAddressID) {
      return await axios
        .post(
          shopService_dotnet + "Shop/get-shipping-address-list",
          { id: shippingAddressID, isAscending: true },
          { headers: this.header_token }
        )
        .then(async (res) => {
          return res.data.shift();
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async getShopAddress(shopAddressID) {
      return await axios
        .post(
          shopService_dotnet + "ShopAddress/get-shop-address",
          { id: shopAddressID },
          { headers: this.header_token }
        )
        .then(async (res) => {
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async getStockShopByPack() {
      return await axios
        .post(
          stockService_dotnet + "Stock/get-stock-shop-by-pack",
          {
            shopID: this.orderInfo.shopID,
            search: this.packingCodeAdd,
            OrderMasterID: this.orderInfo.id,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async GetCheckInfoNeedForPack(dateUpdated) {
      return await axios
        .post(
          orderService_dotnet + "Order/check-info-need-for-pack",
          {
            shopID: this.currentShopID,
            branchID: localStorage.getItem("Branch_BranchID"),
            updatedTimeStamp: dateUpdated,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          return res.data;
          
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async savePackOrder(body) {
      console.log("bodyเข้ามา",body)
      return await axios
         .post(
          orderService_dotnet + "Order/save-pack-order-v2",
           body ,
          { headers: this.header_token }
        )
        .then(async (res) => {
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async splitPackage(body) {
      return await axios
         .post(
          orderService_dotnet + "Package/split-package",
          {items:body},
          { headers: this.header_token }
        )
        .then(async (res) => {
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async getGoogleDriveFolder() {  
      return await axios
         .post(
          generalService_dotnet + "GoogleDrive/get-branch-google-drive",
          {
            "branchID": localStorage.getItem("Branch_BranchID")
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          return res.data.item;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async updateCreateSubfolder(FolderType,clentJson,FolderDocs) {
         let folder_1 = this.isGetFolderGoogleDrive[0]
         console.log(folder_1); 
        axios.post( generalService_dotnet + "GoogleDrive/update-branch-google-drive",
        {
          "branchID": localStorage.getItem("Branch_BranchID"),
          "folderID": FolderType  == 1 ? folder_1.folderID : FolderDocs.id,
          "folderName": FolderType  == 1 ? folder_1.folderName : FolderDocs.name,
          "clentJson": FolderType == 1 ? clentJson : "" ,
          "FolderType": FolderType
        }
        ).then(async (res) => { 
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
         
    
      
    },
    async upLoadFileGoogleDrive(jsonClent,folderSub,recoredFile){

       let accessToken = jsonClent.access_token; // แทนที่ด้วย Access Token ที่ดึงมา
       let parentId = folderSub.id; // เปลี่ยนเป็น ID ของโฟลเดอร์ที่ต้องการอัปโหลด
       let fileName = "test01";
       const formData = new FormData();
       console.log("resip[]",formData);
      formData.append("title", "asdasdasd");
      formData.append("file", this.File);
      formData.append('metadata', new Blob([JSON.stringify({ name: fileName, parents: [parentId] })], { type: 'application/json' }));
        console.log("resip[]",formData);
          // Get an upload session
           
          let res =  axios.post("https://www.googleapis.com/upload/drive/v3/files", 
          
          {
            'metadata':new Blob([JSON.stringify({ name: fileName, parents: [parentId] })], { type: 'application/json' })
          }, {
          headers: { Authorization: `Bearer ${accessToken}` },
        }).then((response) => {
          console.log(response.data)
        })
        .catch(async(error) => {
          if(error.response.status == 401){
            console.log("refreshAccessToken");
            let refreshAccessToken =   await this.refreshAccessToken();
            console.log("refreshAccessToken",refreshAccessToken);

              await this.upLoadFileGoogleDrive(this.jsonClent,this.folderSub[0],this.recordedBlob);
            }
        })
       console.log("resip[]",res);
    },
    async upLoadFile(){
   
    let dateMomnet = moment(new Date()).format("YYYYMMDD_hhmmss");
    const fileName =  this.ChangeReferenceInfo(this.OrderID) + "_" + dateMomnet;
    let body = [{fileName :fileName ,status:"กำลังโหลด",thumbnail: this.thumbnailImg}] ;
    this.isWaitingUploadGoogleDrive.push(...body);
  
      const parentId = this.folderSub.id; // เปลี่ยนเป็น ID ของโฟลเดอร์ที่ต้องการอัปโหลด
      const formData = new FormData();

      formData.append("file",this.recordedBlob);
      formData.append('metadata', new Blob([JSON.stringify({ name: fileName+".mp4", parents: [parentId] })], { type: 'application/json' }));

        // Get an upload session
        const res = await  axios
    .post("https://www.googleapis.com/upload/drive/v3/files", formData, {
      headers: { Authorization: `Bearer ${this.jsonClent.access_token}` },
    }).then((response) => {
      let find =this.isWaitingUploadGoogleDrive.find(i => i.fileName == fileName );
          //ปรับสถานะของไฟล์อัพโหลด
          find.status = "สำเร็จ"
          this.countSuccessUpload++
      console.log(response.data)
    })
    .catch(async (error) => {
      if(error.response.status == 401){
       let remove =  this.isWaitingUploadGoogleDrive.findIndex(item => item.fileName  == fileName )
        this.isWaitingUploadGoogleDrive.splice(remove, 1);
        this.isWaitingUploadGoogleDrive = this.isWaitingUploadGoogleDrive.find(item => !this.shouldRemove(item));

            let refreshAccessToken =   await this.refreshAccessToken();
            this.jsonClent.access_token  = refreshAccessToken.access_token
            await this.updateCreateSubfolder(1,JSON.stringify(this.jsonClent),this.folderSub[0])
            // this.updateCreateSubfolder(2,this.jsonClent,res.data)
              await this.upLoadFile(this.jsonClent,this.folderSub[0],this.recordedBlob);
            }
      console.log(error)
    })
       
    },
    async createShipment(orderMasterID, packageID) {
      console.log("orderMasterID, packageID",orderMasterID, packageID);
      return await axios
        .post(
          logisticService_dotnet + "Logistic/create-shipment-V2",
          {
            orderMasterID: orderMasterID,
            packageID: packageID,
            staffBranchID: localStorage.getItem("Branch_StaffID"),
            isNeedAddress: true,
            isNeedProductList: true,
            isForceNewTrack: false,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (
            res.data.status == true &&
            (res.data.message == null || res.data.message == "")
          ) {
            return await res.data;
          } else {
            // this.AlertError(res.data.message);
            return res.data;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },
  

    //ส่วนของ function
    async addItemPacking(packageItem) {
      let foundItem = packageItem.items.find(
        (item) =>
          item.barcode === this.packingCodeAdd.trim() ||
          item.sku === this.packingCodeAdd.trim()
      );

      let addItem = this.addItemsIncrease;
      if (foundItem) {
        foundItem.packedQuantity  += addItem;
        this.addItemsIncrease = 1;
        let PackCost =await this.calculatePackCost( packageItem)
        let packPrice = packageItem.selected.length > 0 ? packageItem.selected[0].packPrice  : 0 ;
        this.$set(packageItem, "packPrice", PackCost + packPrice );
        console.log("packageItem",packageItem)

      } else {

        
        let checkStock = []
        checkStock.message = "ไม่พบสินค้า";
        this.alertNotFoundStock(checkStock.message);
      }

      this.packingCodeAdd = "";
    },
    async DeleteItemEachPacking(index,packageItem,removeType) {
      console.log("packageItem",packageItem ,"packageItem.items[index].packedQuantity",packageItem.items[index].packedQuantity)

      if (await packageItem.items[index].packedQuantity >= 1) 
      {
        if(removeType == 1 ){
          packageItem.items[index].packedQuantity--;
        }
        else if(removeType == 2 ){
          packageItem.items[index].packedQuantity = 0
        }
       
        let PackCost =await this.calculatePackCost(packageItem)
        let packPrice =await packageItem.selected.length > 0 ? packageItem.selected[0].packPrice  : 0 ;
        this.$set(packageItem, "packPrice", (PackCost) + packPrice );
      }
    },
    changeColorHeader(item) {
      if (item.isLock) {
        if( item.isBox){
          return "";
        }
        else if(item.quantity==null){
          return "red";
        }
        else if (!item.packedQuantity || item.packedQuantity == 0 || item.isBox) {
          return "";
        } else if (item.packedQuantity == item.quantity) {
          return "lightgreen";
        } else if (item.packedQuantity < item.quantity) {
          return "amber";
        } else if (item.packedQuantity > item.quantity) {
          return "orange";
        }
      } else {
        return "";
      }
      return "";
    },
    clearOrder() {
      this.orderInfo = {};
      this.orderDetail = {};
      this.orderItem.item = {};
      this.orderItemTemp = {};
      this.show = false;
      this.OrderID = null;
      this.$refs.OrderID.focus();
    },
    async CheckInfoNeedForPack() {
      let PackSize = JSON.parse(localStorage.getItem("PackageSize")) || [];

      // ค้นหา box size จาก shopID
      let foundShopID = null;
      if (PackSize != null) {
        foundShopID = PackSize.find((item) => {
          return item.id == this.currentShopID;
        });
      }
    
      let updatedTimeStamp = foundShopID ? foundShopID.updatedTimeStamp : null;

      let PackSizeRes = await this.GetCheckInfoNeedForPack(updatedTimeStamp);
     
      if (PackSizeRes.status == "success") {
        if (PackSizeRes.shop == null) {
          return foundShopID;
        } else {
          if (foundShopID != null) {
            let index = PackSize.indexOf(foundShopID);
            PackSize.splice(index, 1, PackSizeRes.shop);
             
          } 
          PackSize.push(PackSizeRes.shop);
          localStorage.setItem("PackageSize", JSON.stringify(PackSize));
          return PackSizeRes.shop;
        }
      } else if (PackSizeRes.error_description) {
        this.alertNotFoundStock(PackSizeRes.error_description);
        return false;
      }
      

      return false;
    },
    async getPostBox(packageItem, obj) { 
        let foundExpress=null;
        if(packageItem.uniqueID !=null && packageItem.uniqueID!="") {
          foundExpress = obj.shop_Express.find( item  =>   item.uniqueID == packageItem.uniqueID);
        }
        if(foundExpress==null){
          if(packageItem.expressCompanyID !=null) {
            foundExpress = obj.shop_Express.find( item  =>   item.expressCompanyID == packageItem.expressCompanyID);
          }
          else{
            return null;
          }
        }
        
          
      return foundExpress ;
    },
    async calculatePackCost(  packageItem) {
      if (packageItem.packingChargedType == 1) {
        return packageItem.packPrice ;
      } else if (packageItem.packingChargedType == 2) {
        return 0;
      } else if (packageItem.packingChargedType == 3) {
        // หาจำนวนสินค้าทั้งหมด และ แยกกล่องออกจากสินค้า
        let quantityCount = packageItem.items.reduce((sum, item) => {
          return (
            sum +
            (item.isBox
              ? 0
              : item.packedQuantity == null
              ? 0
              : item.packedQuantity)
          );
        }, 0); 
        return await this.subCalculatePackCost( packageItem.packingPriceSteps,quantityCount) ;

      } else if (packageItem.packingChargedType == 4) {
        // กรณี SKU
        const resultCountOrder = packageItem.items.filter((item) => {
          return !item.isBox;
        });
        let countOrder = resultCountOrder.length;
        return await this.subCalculatePackCost( packageItem.packingPriceSteps, countOrder) ;
      }
    },
    async subCalculatePackCost(packingPriceSteps, quantityCount) {
      let SumPackPrice = 0;
      let quantityLeft = quantityCount;
      let newStep = packingPriceSteps.sort((a,b) => a.start - b.start)
      
      newStep.forEach((item) => {
        if (quantityLeft > 0) {
          if (item.end == null) {
            SumPackPrice = SumPackPrice + (quantityLeft * item.price);
             return  SumPackPrice
          } else {
            let Range = parseInt(item.end) - item.start + 1;
            if (Range > quantityLeft) {
              Range = quantityLeft;
            }
            SumPackPrice = SumPackPrice + (Range * item.price);
            quantityLeft = quantityLeft - Range;

          }
        } else {
          return SumPackPrice;
        }
      });

      return SumPackPrice;
    },
    async validateSavePack() {

      for (const packages of this.orderItem.packages) {
        for (const item of packages.items) {
           //วนหาข้อมูล แพ็คจริง กับ จำนวนต้องแพ็ค
          if (item.packedQuantity !== item.quantity) 
             {
              return false;
             }
          
          }
        
        if(this.orderInfo.boxSizeExpressCompany.isIgnoreWeight == false){
          if(packages.weight == null){
          return false;
        }
        }
        

        if(this.orderInfo.boxSizeExpressCompany.isIgnoreDimension == false){
          if(packages.cubic1 == null || packages.cubic2 == null || packages.cubic3 == null ){
          return false;
        }
        }
       
        }
        return true;
    },
    async setBodyForPack(){

      let body = {}
      let packageList =[]
      
        await this.orderItem.packages.forEach(obj => {
        var parts =  obj.expressUniqueID.split('_');
        //isStockShortage แปลงจากnull
         obj.items.forEach(x => 
         {
          if(x.isStockShortage == null)
          {
            x.isStockShortage = false
          }
         }
        );
      
        packageList.push({
            id:obj.packageID,
            packageNo: obj.packageNo,
            expressCompanyID : parts[2],
            packedByStaffBranchID : parseInt(localStorage.getItem("Branch_StaffID")), 
            weight : obj.weight,
            cubic1: obj.cubic1,
            cubic2: obj.cubic2,
            cubic3: obj.cubic3,
            boxName : obj.boxName,
            codAmount: this.orderPackageSize.codFee,
            codCharged : obj.codCharged,
            codPercent: this.orderPackageSize.percentCod,
            branchBoxCost : obj.boxCostPrice ,
            deliveryPrice : obj.deliveryPrice,
            boxPrice : obj.boxPrice,
            packPrice : obj.packPrice,
            remotePrice : obj.remotePrice,   
            bubblePrice : obj.bubblePrice,
            otherServicePrice : obj.otherServicePrice,
            vrichTransactionFee : obj.vrichTransactionFee,
            discount : obj.discount,
            branchExpressID : parts[0],
            shopExpressID :  parts[1],
            productList :  obj.items
            
        })
      })

      body.orderMasterID = this.orderInfo.id
      body.packageList = await packageList;
      body.shopBankAccountID = null
      body.photoList = null
                      // [
                      //   {
                      //     "id": 0,
                      //     "orderMasterID": 0, ตัวอย่างถ้ามีค่า
                      //     "url": "string"
                      //   }
                      // ],
        
        
       
        return await body;
    },
    async changedExpress(selectedValue,packageItem){
       
        let itemValue = {uniqueID:selectedValue};

        let item  = await this.getPostBox(itemValue,this.orderInfo.boxSizeExpressCompany)

        if(item)
        { 
         console.log(packageItem);
          packageItem.expressCompanyID = item.expressCompanyID;
          packageItem.branchExpressID = item.branchExpressID;
          packageItem.shopExpressID = item.shopExpressID;
          packageItem.uniqueID = item.uniqueID;
          packageItem.packingChargedType = item.packingChargedType;
          packageItem.postboxes = item.postboxes;
          packageItem.packingPriceSteps =  item.packingPriceSteps;
          packageItem.selected = [];

          let box = item.postboxes.find((item) =>  item.name === packageItem.boxName);
          packageItem.selected = box ? [box] : []
        }
        this.calculatePackCost(packageItem);
               
    },
    async selectedBox(event,packageItem){
      if(event.value){
        this.$set(packageItem, "boxName", event.item.name);
        this.$set(packageItem, "boxPrice", event.item.packAndBoxPrice);
        this.$set(packageItem, "bubblePrice", packageItem.bubble ? event.item.bubblePrice: 0);
        this.$set(packageItem, "cubic1", event.item.cubic1);
        this.$set(packageItem, "cubic2", event.item.cubic2);
        this.$set(packageItem, "cubic3", event.item.cubic3);
        this.$set(packageItem, "boxCostPrice", event.item.boxCostPrice);
        
        // console.log("B00219777",event.item,"packageItem",packageItem)
        let PackCost =await this.calculatePackCost(packageItem)
        this.$set(packageItem, "packPrice", PackCost + event.item.packPrice);
      }
     

    },
    async setBodyForSplitPackage(item){

      let items = item.map(item => ({
          
        orderItemID: item.orderItemID,
        quantity :parseInt(item.packedQuantity) 
      })); 
    return items;

    
    },
     ShowSplitMenu(item){ 
      let cloneItem = [...item];
      let CheckOverQuntity = cloneItem.filter(obj => 
        (item.length > 1 && obj.packedQuantity > 0 && obj.packedQuantity <= obj.quantity  )
       ||(item.length == 1 && obj.packedQuantity < obj.quantity &&  (obj.quantity >= 2 &&  obj.packedQuantity >= 1) ) 
      )
      if(CheckOverQuntity.length > 0  ){
        return true;
      }
      return false;
    },
    async UploadGoogleDrive(){
      //สำหรับบังคับห้ามออก 
      window.removeEventListener('beforeunload', this.beforeUnload);
      window.removeEventListener('keydown', this.handleKeyDown);

      let resRefreshAccessToken =[];
      let newFolderSub = [];
      let findFolder =  await this.getGoogleDriveFolder();
      let folderSub = findFolder.filter(f => f.folderType === 2);
      this.jsonClent = await  JSON.parse(findFolder[0].clentJson);
      let matchingItems = folderSub.filter(item => this.isCurrentDate(item.folderName));
      if(matchingItems == undefined || matchingItems.length <= 0){
      
       let resCreate  = await this.createFolder(this.jsonClent,findFolder[0].folderID)  
        if(resCreate == 401){
          resRefreshAccessToken= await this.refreshAccessToken();
          this.jsonClent.access_token  =  resRefreshAccessToken.access_token
           newFolderSub =  await this.createFolder(this.jsonClent,findFolder[0].folderID);

        }
      }else{
        this.folderSub = matchingItems
        this.folderSub.id = matchingItems[0].folderID
      }
      // if(this.folderSub != null){
      //   matchingItems = this.folderSub;
      //   this.folderSub.id = this.folderSub.folderID
      // }
      this.upLoadFile();
   
    },
    async generateThumbnail() {
      const video = this.$refs.video;
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageUrl = canvas.toDataURL('image/png');
      this.thumbnailImg = (imageUrl);
         
    },
    async convertBytesToPDF(byteArrays) {
      // สร้างเอกสาร PDF ใหม่
      var pdfDoc = await PDFDocument.create();

      for (let i = 0; i < byteArrays.length; i++) {
        var byteArray = byteArrays[i];
        var buffer = Buffer.from(byteArray, "base64");
        var uint8Array = new Uint8Array(buffer);

        // โหลดเอกสาร PDF
        var pdfDocToAdd = await PDFDocument.load(uint8Array);

        // คัดลอกหน้าจากเอกสาร PDF ที่โหลดมา
        var copiedPages = await pdfDoc.copyPages(
          pdfDocToAdd,
          pdfDocToAdd.getPageIndices()
        );

        // แทรกทุกหน้าลงในเอกสารใหม่
        for (var copiedPage of copiedPages) {
          pdfDoc.addPage(copiedPage);
        }
      }
      // แปลง PDF เป็น bytes
      var pdfBytes = await pdfDoc.save();

      //   // บันทึกเอกสาร PDF
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(blob);

      const newWindow = window.open(pdfUrl);

      newWindow.print();
    },
    async convertBase64HtmlToArrayBuffer(base64Html) {
      const htmlString = decodeURIComponent(escape(atob(base64Html)));
      let iframe = document.createElement("iframe");
      iframe.style.visibility = "hidden";
      document.body.appendChild(iframe);
      let iframedoc = iframe.contentDocument || iframe?.contentWindow?.document;
      if (!iframedoc) return;
      iframedoc.body.innerHTML = htmlString;
      let canvas = await html2canvas(iframedoc.body, {});
      let imgData = canvas.toDataURL("image/png");
      const doc = new jsPDF({
        format: "a4",
        unit: "mm",
      });
      doc.addImage(imgData, "PNG", 0, 0, 210, 297);
      document.body.removeChild(iframe);
      return doc.output("arraybuffer");
    },
    async CreateAwb() {
      let dataOUT = [];
    
        if (
          this.orderInfo.referenceSourceID == 3 ||
          this.orderInfo.referenceSourceID == 4 ||
          this.orderInfo.referenceSourceID == 11
           ) 
          {
          let data = await this.getAwb(this.orderItem.packages.packageID);
          if (data.bytes != null) { dataOUT.push(data.bytes); }   
           else if (data.base64HTML != null) 
           {
            let bytes = await this.convertBase64HtmlToArrayBuffer(data.base64HTML);
            dataOUT.push(bytes);
           }
          } 
        else 
        {    
          let data = await this.createShipment(this.orderItem.packages[0].orderMasterID, this.orderItem.packages[0].packageID);
          if (data.status == true && (data.message == null || data.message == "")) 
          {
            dataOUT.push(data);  
          } else 
          {
            this.alertStop();
          }
        }

      if (
        this.orderInfo.referenceSourceID == 3 ||
        this.orderInfo.referenceSourceID == 4 ||
        this.orderInfo.referenceSourceID == 11
        ) 
        {
        if (dataOUT.length > 0) { this.convertBytesToPDF(dataOUT);   }    
       } else 
       {
        var key = Math.floor(Math.random() * 1000000 + 1).toString();
        localStorage.setItem("printAws", JSON.stringify(dataOUT));
        sessionStorage.setItem("encodedQueryKey_printAws", key);
        window.open(`/printAws?key=${key}`);
       }
     
    },
    //สร้าง Folder ใหม่
    async createFolder(jsonClent,findFolder){ 
      const accessToken  = await jsonClent.access_token; // แทนที่ด้วย Access Token ที่ดึงมา
      const parentId = findFolder; // เปลี่ยนเป็น ID ของโฟลเดอร์ที่ต้องการอัปโหลด

      var date = moment(new Date()).format("YYYY_MM_DD");
       return await axios.post("https://www.googleapis.com/drive/v3/files", 
         {
          mimeType: "application/vnd.google-apps.folder",
          name: date,
          parents: [parentId]
        },  {
          headers: 
          {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          }
           })
         .then((res) => {
            this.updateCreateSubfolder(2,this.jsonClent,res.data)
            return this.folderSub = res.data
          })
          .catch(async (error) => {
            console.log(" error", error.response.status)
            return error.response.status; 
          })
    
    },
    async refreshAccessToken(){
      const data = new URLSearchParams();
      data.append('refresh_token', this.jsonClent.refresh_token);
      data.append('client_secret', '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');
      data.append('grant_type', 'refresh_token');
      data.append('client_id', '73555754893-5sjbfn01j3lhjijla4jgtmd361dq4k6h.apps.googleusercontent.com');
        return    axios.post('https://oauth2.googleapis.com/token?', 
        data.toString(), // Convert URLSearchParams to string
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then(res => {
        return  res.data;
      }).catch(error => {
        console.error(error);
        return error
     
      });
    },
    async isCurrentDate(dateString) {
      const dateRegex = /(\d{4})[_/](\d{2})[_/](\d{2})|(\d{2})[_/](\d{2})[_/](\d{4})/;

      const match = dateString.match(dateRegex);
      if (match) {
        let year, month, day;

        if (match[1] && match[2] && match[3]) {  // YYYY_MM_DD or YYYY/MM/DD
          year = match[1];
          month = match[2];
          day = match[3];
        } else if (match[4] && match[5] && match[6]) {  // DD/MM/YYYY or DD_MM_YYYY
          day = match[4];
          month = match[5];
          year = match[6];
        }


        const dateFromItem = new Date(year, month - 1, day); // JavaScript month is 0-indexed
        const today = new Date();
        
        return dateFromItem.toDateString() === today.toDateString();
      }
      return false;
    },
    //ส่วนของ Btn
    async btnSearchOrderID() {
      this.currentShopID=null;
      const OrderID = this.OrderID.trim();
      this.orderInfo = {};
      this.orderDetail = {};
      this.orderItem.item = {};
      this.orderItemTemp = {};
      this.show = false;
      // this.stopRec() 
      if (OrderID) {
        this.loading = true;
        try {
          var ID = this.ChangeReferenceInfo(OrderID );
          var OrderMasterID = parseInt(ID) ? parseInt(ID) : 0;
          var TrackingNo = ID;

          //เช็ค สถานะ PA ว่าสามารถแพ็คสินค้าได้ไหม
          const orderMasterRes = await this.getCheckOrderStatusBeforePack(
            OrderMasterID,
            TrackingNo
          );
          if (orderMasterRes) {
            this.currentShopID = orderMasterRes.shopID;
            //เรียก API ดึงรายละเอียดลูกค้า
            var resOrdermasterForPack_job =   this.getOrderMasterForPack(orderMasterRes.orderMasterID);
            var item_job =   this.getOrderItemPackageForPack(orderMasterRes.orderMasterID);
            var itemTemp_job =   this.getOrderItemTemp( orderMasterRes.orderMasterID);
            let boxSizeExpressCompany_job =   this.CheckInfoNeedForPack();


            var resOrdermasterForPack =await resOrdermasterForPack_job;
            var item =await item_job;
            var itemTemp =await itemTemp_job;
            let boxSizeExpressCompany =await boxSizeExpressCompany_job;


            this.orderInfo = await resOrdermasterForPack.data; 

            //สำหรับเช็คว่าค่าแพ็คอิงตาม SHOP
          
 
            this.orderInfo.expressChannel =   boxSizeExpressCompany.shop_Express;
            this.orderInfo.expressChannelToShow =  this.orderInfo.expressChannel.filter(item=>item.referenceSourceID==null || !this.platformReferenceSourceID.includes(item.referenceSourceID))
            this.orderInfo.boxSizeExpressCompany =   boxSizeExpressCompany;
           
            //set กล่องเข้าไปในแต่ packages
            for(var packageItem of item.packages)
            {
              console.log(packageItem);
              if(packageItem.expressCompanyID != null)
              {
              //ดึงค่ากล่อง
                let expressItem = await this.getPostBox(packageItem,boxSizeExpressCompany)
          
                packageItem.packingPriceSteps = expressItem.packingPriceSteps
                packageItem.packingChargedType = expressItem.packingChargedType
                packageItem.postboxes = expressItem.postboxes
                packageItem.expressCompanyID = expressItem.expressCompanyID;
                packageItem.branchExpressID = expressItem.branchExpressID;
                packageItem.shopExpressID = expressItem.shopExpressID;
                packageItem.uniqueID = expressItem.uniqueID;
                //หาว่ามีค่ากล่องที่เลือกไหม
                let box = expressItem.postboxes.find((item) =>  item.name === packageItem.boxName);
                packageItem.selected = box ? [box] : []
              }
              else{
                packageItem.postboxes = []
                packageItem.packingPriceSteps = null
                packageItem.packingChargedType = null
                packageItem.selectBox = []
              }
            }
           
            this.orderItem.packages = item.packages;
            this.orderItemTemp = itemTemp;
            //เรียก API ที่อยู่ผู้ส่ง
             

            this.OrderID = OrderID;
            this.show = true;
            this.loading=false;
            

          } else {
            this.$refs.OrderID.focus();
          }
           
          if(this.isGoogleDrive == true){
            this.startRec();
          }

          console.log("  this.orderInfo",  this.orderInfo);
          console.log("  this.this.orderDetail",  this.orderDetail);
          
        } catch (e) {
          console.log(e,"เกิดปัญหา");
          this.loading = false;
          this.show = false;
          this.audioBuzzer.play()
          this.AlertError();
        }
      }
    },
    async btnBoxSize(packageItem) {
      this.$set(packageItem, "boxName", !packageItem.boxName ? 0 :packageItem.boxName);
      this.$set(packageItem, "boxPrice", !packageItem.boxPrice? 0:packageItem.boxPrice );
      this.$set(packageItem, "cubic1", !packageItem.cubic1 ? 0 :packageItem.cubic1);
      this.$set(packageItem, "cubic2", !packageItem.cubic2 ? 0 :packageItem.cubic2);
      this.$set(packageItem, "cubic3", !packageItem.cubic3 ? 0 :packageItem.cubic3);
      if(packageItem.bubble){
       
        this.$set(packageItem, "bubblePrice", packageItem.selected.length > 0 ? packageItem.selected[0].bubblePrice :0 );
      }else{
        this.$set(packageItem, "bubblePrice", 0);

      }
 
 
      
    },
    async btnSubmitSavePack() {
      
      
      this.loading = true
      let body = [];
      try {
         if(await this.validateSavePack()){
          body = await this.setBodyForPack();
          console.log("this.isGoogleDrive",this.isGoogleDrive);
          if(this.isGoogleDrive)
          { 
            await this.generateThumbnail()
            await this.stopRec() 
          }
          await this.savePackOrder(body);
          if(this.isGoogleDrive){ 
          await this.UploadGoogleDrive() 
          this.UpDownloadingGoogleDrive = true
          }
          this.loading = false;
          this.clearOrder();
         }else{
          this.alertStop();
          this.loading = false;
         }    
      } catch (error) 
      {
        this.alertStop();
        this.loading = false;     
      }
    },
    async btnSubmitAwb() {
      
      
      this.loading = true
      let body = [];
      try {
         if(await this.validateSavePack()){
          body = await this.setBodyForPack();
          if(this.isGoogleDrive)
          { 
            await this.generateThumbnail()
            await this.stopRec() 
          }
          await this.savePackOrder(body);
          if(this.isGoogleDrive){ await this.UploadGoogleDrive() }
          this.loading = false;
          this.CreateAwb();
          this.clearOrder();
         }else{
          this.alertStop();
          this.loading = false;
         }    
      } catch (error) 
      {
        this.alertStop();
        this.loading = false;     
      }
    },
    async btnSplitPackage(item){
      let cloneItem = [...item];

      let CheckOverQuntity = cloneItem.filter(obj => 
        (item.length > 1 && obj.packedQuantity > 0 && obj.packedQuantity <= obj.quantity  )
       ||(item.length == 1 && obj.packedQuantity <= obj.quantity &&  (obj.quantity >= 2 &&  obj.packedQuantity >= 1) ) 
      )


      this.splitPackageDataSet.dataSet = [...CheckOverQuntity];

     
      this.splitPackagePopup.dialog = true

    },
    async btnSubmitSplitPackage(){ 
      let item  = this.splitPackageDataSet.dataSet;
      if(item.length > 0){
        
          let bodyAPI = await this.setBodyForSplitPackage(item)
          if(await this.alertConfirmText("ต้องการแยกกล่องใช้ หรือ ไม่ ") == true){
            await this.splitPackage(await bodyAPI);
            await this.btnSearchOrderID();
            this.splitPackagePopup.dialog = false;
          }
          
        
        
      }
 
    },
    async btnBack(){
      if(this.isGoogleDrive == true)
      {
        this.stopRec()
      }
      this.$router.push("/dashboard-statistics");
      
      
    },
  

    
    //ส่วนของ Alert
    async alertConfirm(obj) {
      return Swal.fire({
        icon: "question",
        text: obj.description,
        confirmButtonText: "ใช้",
        cancelButtonText: "ไม่ใช้",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          return obj;
        } else {
          return false;
        }
      });
    },
    async alertStopPack(obj) {
      this.audioBuzzer.play()
      return Swal.fire({
        icon: "warning",
        text: obj.description,
        confirmButtonText: "ตกลง",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then((result) => {
        return false;
      });
    },
    async alertNotFoundStock(message) {
       
      this.audioBuzzer.play()
      return Swal.fire({
        icon: "warning",
        text: message,
        confirmButtonText: "ตกลง",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then((result) => { 
        return false;
      });
    },
    async alertConfirmText(description) {
      return Swal.fire({
        icon: "question",
        text: description,
        confirmButtonText: "ใช้",
        cancelButtonText: "ไม่ใช้",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          return true;
        } else {
          return false;
        }
      });
    },
    async alertStop(){
      Swal.fire({
      // title: "The Internet?",
      text: "ไม่สามารถทำรายการได้",
      icon: "error"
    });
    },
    //ส่วนของอัดวีดีโอ
    async startRec() {
      this.disabled = true;
      this.downloadReady = false;
      this.isVideoVisible = true;
      this.captureCamera(camera => {
        const video = this.$refs.video;
        video.srcObject = camera;
        this.recorder = RecordRTC(camera, {
          type: "video"
        });
        this.recorder.startRecording();
        // release camera on stopRecording
        this.recorder.camera = camera;
        this.disabled = true;
      });
    },
    async stopRec() {
      this.disabled = false;
      this.recorder.stopRecording(this.stopRecordingCallback);
    },
    async captureCamera(callback) {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(function(camera) {
          callback(camera);
        })
        .catch(function(error) {
          // alert("Unable to capture your camera. Please check console logs.");
          console.error(error);
        });
    },
    async stopRecordingCallback() {
      const video = this.$refs.video;
      video.src = video.srcObject = null;
      video.muted = false;
      video.volume = 1;
      this.recordedBlob = this.recorder.getBlob();
      video.src = URL.createObjectURL(this.recordedBlob);
      
      this.downloadReady = true;
      this.recorder.camera.stop();
      this.recorder.destroy();
      this.recorder = null;
      this.isVideoVisible = false; // ซ่อนวีดีโอเมื่อหยุดการบันทึก
    },
    //ถ่ายภาพแปลงเป็น base64
    async captureImage() {
      const video = this.$refs.video;
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageUrl = canvas.toDataURL('image/png');
      this.capturedImages.push(imageUrl);

    console.log( this.capturedImages);
    },
    async downloadRec() {
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(this.recordedBlob);
      downloadLink.download = 'recording.mp4';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    async downloadImage(image, index) {
      const downloadLink = document.createElement('a');
      downloadLink.href = image;
      downloadLink.download = `captured_image_${index + 1}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    //ส่วนของ function ที่เรียกใช้จากข้างนอก component
    openPhoto(linkPhoto) {
      this.picturesShow.ShowPhotoLink = linkPhoto;
      this.picturesShow.ShowPhoto = true;
    },
    closePhoto(value) {
      this.picturesShow.ShowPhoto = value;
      this.picturesShow.ShowPhotoLink = "";
    },
    openAddItemsIncrease(packageItem){
      
      Swal.fire({
      title: "จำนวน",
      input: "number",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
        min:'1'
      },
      inputValue: '1',
      inputValidator: (value) => {
      
        const numValue = Number(value);
        console.log("value",numValue)
        if (numValue == 0 ) {

          return this.openAddItemsIncrease(packageItem);
        }
        this.addItemsIncrease = numValue;
         if(this.packingCodeAdd != null) {
          this.addItemPacking(packageItem);
         }
      }
    });
    },
    //ส่วนของ block website กดปิด 
    beforeUnload(event) {
      event.preventDefault();
      event.returnValue = '';
    },
    handleKeyDown(event) {
      if (event.key === 'F5' || (event.ctrlKey && event.key === 'r')) {
        event.preventDefault();
      }
    },

    //ส่วนของ import
    ChangeReferenceInfo,
    AlertError,
  },
};
</script>

<style scoped>
.lightgreen{
  background-color: #beffc1 !important;
}
.footer {
  position: fixed;
  left: 0;
  z-index: 3;
  bottom: 0;
  width: -webkit-fill-available;
  background-color: white;
  
  /* color: white; */
  /* text-align: center; */
  /* @media (min-width: 1264px) and (max-width: 1903px) {
    margin-left: 256px !important;
  }
  @media (min-width: 1904px) {
    margin-left: 256px !important;
  } */
}
.margin-left256{
  @media (min-width: 1264px) and (max-width: 1903px) {
    margin-right: 256px !important;
  }
  @media (min-width: 1904px) {
    margin-right: 256px !important;
  }
}
.header {
  /* z-index: 3; */
  /* position: fixed; */
  left: 0;
  /* top: 66px; */
  width: -webkit-fill-available;
  background-color: white;
  /* text-align: center; */

  @media (min-width: 1264px) and (max-width: 1903px) {
    margin-right: 256px !important;
  }
  @media (min-width: 1904px) {
    margin-right: 256px !important;
  }
}
.jt_Start{
  display: flex;
  justify-content: flex-start !important;
}
.jt_end{
  display: flex;
  justify-content: flex-end;
}
</style>